<template>
  <div class="">
    <!-- pic46F94EF3762252A1481D81A2AD55D187.jpg -->
    <img class="rounded" src="@/assets/news-network/lego-fanwelt.jpg" style="height: 330px;">
    <p>
      The LEGO Universe team recently made a bunch of amazing new friends at LEGO Fanwelt (LEGO Fans' World) in Cologne, Germany! The LEGO Company and volunteers from two fan clubs organized the four-day event, which featured more than 200 incredible creations from German and Austrian builders! Everyone's preparations paid off when LEGO Fanwelt 2008 drew more than 70,000 visitors!
    </p>
    <p>
      Folks from the LEGO Universe team had a ball bantering about the game with all their new friends. They also had a ton of fun building with the fans, working together to create a familiar face from LEGO Universe!
    </p>
    <p>Stay tuned to the LEGO Universe News Network to get the details about LEGO Fanwelt 2009!</p>
  </div>
</template>
